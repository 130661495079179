<template>
  <div id="header" class="d-flex justify-content-center">
    <div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-main" :class="{ active: current == 'pool' }" @click="redirect('pool')">POOL</button>
            <button class="btn btn-main" :class="{ active: current == 'swap' }" @click="redirect('swap')">SWAP</button>
            <button class="btn btn-main" :class="{ active: current == 'overview' }" @click="redirect('overview')">OVERVIEW</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["current"],
    methods: {
        redirect(route) {
            this.$router.push('/' + route)
        }
    }
}
</script>

<style>
    #header {
        background: transparent;
    }

    .btn-main {
        color: white;
        border: 2px transparent solid;
        width:10em;
        padding:5px;
        font-size:20px;
    }

    .btn-main:hover {
        color:white;
        border: 2px #d8962c solid;
    }

    .btn-main:focus {
        border: 1px #d8962c solid;
        outline: none;
    }

    .active {
        color:white;
        border: 2px #d8962c solid;
    }

    .logo, .placeholder {
        width: 8em;
    }
</style>