<template>
    <div class="alert" :class="{ 'alert-danger': isError, 'alert-success': !isError }">
        {{ error }}
    </div>
</template>

<script>
export default {
    props: ["error", "isError"]
}
</script>

<style>
    .alert-danger {
        color: #610303;
        background-color: #f7c2c2;
        border-color: #f7c2c2;
    }

    .alert-success {
        color: #036109;
        background-color: #c2f7cd;
        border-color: #c2f7cd;
    }
</style>